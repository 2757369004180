/* eslint-disable react/prop-types */
import React from 'react'

import SEO from '../components/seo'

const NotFoundPage = ({ location }) => {
  return (
    <>
      <SEO title="404: Not Found" />
      <div className="pt-8 pb-16">
        <h1>Baby Gender Not Found</h1>
        <p className="mt-4">
          You just hit a route that doesn&#39;t exist... the sadness.
        </p>
      </div>
    </>
  )
}

export default NotFoundPage
